import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a8bd695"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["Loader"], {
          key: 0,
          loading: $setup.loading
        }, null, 8, ["loading"]))
      : ($setup.isQuoteOnCosting)
        ? (_openBlock(), _createBlock($setup["QuoteIsOnCosting"], { key: 1 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode($setup["PartDetailsHeader"], {
              part: $setup.part,
              costingVersion: $setup.costingVersion,
              style: _normalizeStyle($setup.containerHeightOffset)
            }, null, 8, ["part", "costingVersion", "style"]),
            _createVNode(_component_router_view, {
              onGotPartData: $setup.setPartData,
              style: _normalizeStyle($setup.partDetailsHeaderHeightOffset),
              costingVersion: $setup.costingVersion
            }, null, 8, ["style", "costingVersion"])
          ], 64))
  ]))
}